<template>
  <header class="header">
    <img src="../../assets/images/LogoVisita.png" alt="Colgate Visita Digital Autoguiada" class="logo" />

    <!-- Ícono del menú hamburguesa -->
    <div class="menu-icon" @click="toggleMenu">☰</div>

    <nav class="nav" :class="{ hidden: showMobileMenu }">
      <router-link to="/" class="nav-link" :class="{ active: isActive('/') }">Inicio</router-link>
      <router-link to="/trainings" class="nav-link" :class="{ active: isActive('/trainings') }">Capacítate</router-link>
      <a href="https://www.colgate.com/es-co" target="_blank" class="nav-link">Descubre más</a>

      <div v-if="isAuthenticated" class="user-menu">
        <div class="profile-icon" @click="toggleDropdown">
          {{ user.correo.charAt(0).toUpperCase() }}
        </div>

        <!-- Menú desplegable -->
        <div v-if="showDropdown" class="dropdown">
          <span class="email-text">{{ user.correo }}</span>
          <button @click="logout" class="logout-button">Cerrar sesión</button>
        </div>
      </div>
    </nav>

     <!-- Menú móvil -->
     <nav class="nav-mobile" :class="{ active: showMobileMenu }">
      <router-link to="/" class="nav-link" @click="closeMenu">Inicio</router-link>
      <router-link to="/trainings" class="nav-link" @click="closeMenu">Capacítate</router-link>
      <a href="https://www.colgate.com/es-co" target="_blank" class="nav-link" @click="closeMenu">Descubre más</a>

      <div v-if="isAuthenticated" class="user-menu-mobile">
        <span class="email-text">{{ user.correo }}</span>
        <button @click="logout" class="logout-button">Cerrar sesión</button>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      showDropdown: false,
      showMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
    user() {
      return this.currentUser;
    },
  },
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      this.logoutUser();
      this.$router.push('/login');
      this.showDropdown = false;
    },
    isActive(route) {
      return this.$route.path === route;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    closeMenu() {
      this.showMobileMenu = false;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 18em;
  height: 8em; 
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 100%;
  width: auto;
}

.nav {
  display: flex;
  gap: 4em;
}

.nav-link {
  color: #595655;
  font-family: 'ColgateReady-Regular';
  font-size: 1.3em; 
  text-decoration: none;
  position: relative;
}

.nav-link:hover,
.nav-link.active {
  color: #595655;
}

.user-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00c700;
  color: #ffffff;
  font-family: 'ColgateReady-Regular';
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-icon:hover {
  background-color: #0f0;
}

.dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #f0f8ff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1em;
  text-align: center;
  width: auto; 
}

.email-text {
  display: block;
  margin-bottom: 0.5em;
  color: #595655;
  font-family: 'ColgateReady-Regular';
  font-size: 1em;
}

.logout-button {
  background-color: #d32f2f;
  color: #ffffff;
  border: none;
  padding: 0.88em 4em;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'ColgateReady-Bold';
  font-size: 0.9em;
}

.logout-button:hover {
  background-color: #b71c1c;
}

/* Responsive styles */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 2em;
  color: #595655;
}

.nav-mobile {
  display: none;
  flex-direction: column;
  gap: 1em;
  background-color: #ffffff;
  padding: 1em;
  position: absolute;
  top: 8em;
  right: 0;
  left: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-mobile .nav-link {
  font-size: 1em;
  padding: 0.5em 0;
}

@media (max-width: 768px) {
  .header {
    padding: 1em;
  }

  .logo {
    height: 60%; 
  }

  .nav {
    display: none; /* Oculta el menú normal en pantallas pequeñas */
  }

  .menu-icon {
    display: block;
  }

  .nav-mobile.active {
    display: flex;
  }
}

/* Estilo del menú móvil */
.nav-mobile {
  display: none;
  flex-direction: column;
  gap: 1em;
  position: absolute;
  top: 8em;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1em;
}

.nav-mobile.active {
  display: flex;
}

/* Estilo del perfil en móvil */
.user-menu-mobile {
  margin-top: 1em;
  border-top: 1px solid #ddd;
  padding-top: 1em;
  text-align: center;
}

.user-menu-mobile .email-text {
  display: block;
  color: #595655;
  font-family: 'ColgateReady-Regular';
  font-size: 1em;
}

.user-menu-mobile .logout-button {
  background-color: #d32f2f;
  color: #ffffff;
  border: none;
  padding: 0.8em 2em;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'ColgateReady-Bold';
  font-size: 0.9em;
}

.user-menu-mobile .logout-button:hover {
  background-color: #b71c1c;
}

/* Ajustes para pantallas medianas (Tablets y computadoras medianas) */
@media (min-width: 769px) and (max-width: 1366px) {
  .header {
    padding: 1em 5em; /* Reduce el padding horizontal */
  }

  .logo {
    height: 70%; /* Ajusta el tamaño del logo */
    width: auto;
  }

  .nav {
    gap: 2em; /* Reduce el espacio entre enlaces */
  }

  .nav-link {
    font-size: 1.1em; /* Disminuye ligeramente el tamaño de los enlaces */
  }

  .menu-icon {
    font-size: 1.5em; /* Ajusta el tamaño del ícono del menú hamburguesa */
  }

  .user-menu .profile-icon {
    width: 35px; /* Reduce el tamaño del icono de perfil */
    height: 35px;
  }

  .dropdown {
    padding: 0.8em; /* Reduce el espacio interno del dropdown */
    font-size: 0.9em; /* Ajusta el tamaño de la fuente */
  }

  .logout-button {
    padding: 0.7em 2em; /* Ajusta el tamaño del botón de cerrar sesión */
    font-size: 0.8em;
  }
}

</style>

