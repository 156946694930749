<template>
  <div>
    <Register />
  </div>
</template>

<script>
import Register from '@/components/auth/Register.vue';

export default {
  components: { Register },
};
</script>
