<template>
  <section class="responsive-section">
    <img src="../../assets/images/slider1.png" alt="" />
  </section>
</template>

<script>
export default {};
</script>

<style>
* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .responsive-section {
      background: #F2F2F2;
      width: 100%;
      height: auto; 
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; 
    }

    .responsive-section img {
      max-width: 100%; 
      height: auto; 
      aspect-ratio: 16 / 9;
      border-radius: 8px; 
    }
</style>
