<template>
  <div id="app">
    <!-- <header>
      <nav>
        <router-link to="/">Inicio</router-link>
        <router-link v-if="!isAuthenticated" to="/login">Iniciar Sesión</router-link>
        <router-link v-if="!isAuthenticated" to="/register">Registrarse</router-link>
        <button v-if="isAuthenticated" @click="logout">Cerrar Sesión</button>
      </nav>
    </header> -->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  /* components: {  }, */
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      this.logoutUser();
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
/* header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #f8f8f8;
} */

nav a, button {
  margin: 0 0.5em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
