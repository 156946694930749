import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import TrainingList from '@/components/trainings/TrainingList.vue';
import TrainingDetail from '@/components/trainings/TrainingDetail.vue';

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { requiresAuth: true }, },
  { path: '/login', name: 'Login', component: Login, meta: { requiresGuest: true }, },
  { path: '/register', name: 'Register', component: Register },
  { path: '/trainings', name: 'Trainings', component: TrainingList },
  { path: '/trainings/:trainingId', name: 'TrainingDetail', component: TrainingDetail },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.path === '/login' && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
