<template>
  <!-- pagina de inicio -->
    
  <Header />
  <SectionOne />
  <SectionTwo />
</template>

<script>
import Header from '@/components/common/Header.vue';
import SectionOne from '@/components/common/SectionOne.vue';
import SectionTwo from '@/components/common/SectionTwo.vue';

export default {
  components: { 
    Header,
    SectionOne,
    SectionTwo 
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>