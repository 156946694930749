<template>
  <section class="login-section">
    <div class="login-container">
      <img src="../../assets/images/LogoVisita.png" alt="Logo" class="top-image" />
      <p>¿Aún no estás registrado?</p><br>
      <router-link to="/register" class="register-link">Registrarse</router-link>

      <hr width="50%"><br>

      <div class="form-wrapper">
      <form @submit.prevent="loginUser" style="width: 60%;">
        <label for="email" class="label-text">Si usted está registrado, ingrese su correo.</label><br><br>
        <input v-model="email" type="email" id="email" class="input-field" placeholder="Correo Electrónico" required /><br><br>
    
        <button type="submit" class="submit-button">Ingresar</button>
      </form>
    </div>
    </div>
    <div class="image-container"></div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return { email: '' };
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post('/api/auth/login', { correo: this.email });
        this.$store.dispatch('loginUser', response.data.user);
        this.$router.push('/');
      } catch (error) {
        console.error('Error al iniciar sesión', error);
      }
    },  
  },
};
</script>

<style scoped>
.login-section {
  display: flex;
  height: 100vh;
}

.login-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  background-color: #f2f2f2;
  position: relative;
}

.top-image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100px; 
  object-fit: contain; 
}

p {
  font-family: 'ColgateReady-Regular';
  color: #595655;
}

.register-text {
  font-size: 1em;
  margin-bottom: 0.5rem;
  text-align: center;
}

.register-link {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  font-family: 'ColgateReady-Bold';
  font-size: 2em; 
  background-color: #009ca6;
  color: white;
  border: 1px solid #00cbda;
  border-radius: 5em; 
  text-decoration: none;
  padding: 9px 50px; 
  width: auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.register-link:hover {
  background-color: #00cbda;
}

.label-text {
  font-family: 'ColgateReady-Regular'; 
  font-size: 1em; 
  color: #595655; 
}

.input-field {
  border: 2px solid transparent;
  border-radius: 2em;
  font-family: 'ColgateReady-Regular';
  font-size: 1em;
  margin: 0.5em 0;
  padding: 1em 1em 1em 0.5em; 
  width: 100%;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #F1B2B6; 
  outline: none; 
}

.submit-button {
  background-color: #d2010d;
  color: white;
  border: none;
  border-radius: 2em;
  padding: 0.6em 2em;
  width: 60%; 
  cursor: pointer;
  font-family: 'ColgateReady-Regular';
  font-size: 1.5em;
  font-weight: 600;
  display: block;
  margin: 0 auto; 
  text-align: center;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f0000c;
}

@media (max-width: 1024px) {
  .register-link {
    font-size: 1.5em;
    padding: 9px 48px;
    width: 50%;
  }
}

@media (max-width: 768px) {
  .register-link {
    font-size: 1.2em;
    padding: 9px 32px;
    width: 60%;
  }
}

@media (max-width: 480px) {
  .register-link {
    font-size: 1em;
    padding: 9px 24px;
    width: 80%;
  }
}

.form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.login-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.login-form input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.image-container {
  width: 50%;
  background-image: url('../../assets/images/imgportada.png');
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .image-container {
    display: none;
  }

  .login-container {
    width: 100%;
  }
}
</style>