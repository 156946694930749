<template>
  <Header />

  <section class="training-detail">
    <h1 class="training-title">{{ training.nombre }}</h1>
    <div v-if="training.video_url" ref="vimeoPlayer" class="video-container"></div>
    <p v-else class="loading-text">Cargando video...</p>
  </section>

  <section class="training-description-container">
    <h3 class="description-title">Descripción</h3>
    <div class="description-box">
      <p>{{ training.descripcion }}</p>
    </div>
  </section>
</template>

<script>
import Header from '@/components/common/Header.vue';
import axios from 'axios';
import Player from '@vimeo/player';
import Swal from 'sweetalert2';

export default {
  components: { 
    Header 
  },
  data() {
    return {
      training: {},
    };
  },
  async created() {
    const trainingId = this.$route.params.trainingId;
    await this.fetchTraining(trainingId);
    this.initializeVimeoPlayer();
  },
  methods: {
    async fetchTraining(trainingId) {
      try {
        const response = await axios.get(`/api/trainings/${trainingId}`);
        this.training = response.data;
      } catch (error) {
        console.error('Error al obtener la capacitación', error);
      }
    },
    initializeVimeoPlayer() {
      if (this.training.video_url) {
        const options = {
          url: this.training.video_url,
          width: 1100,
        };
        this.player = new Player(this.$refs.vimeoPlayer, options);

        this.player.on('ended', this.markAsViewed);
      }
    },
    async markAsViewed() {
      const userId = this.$store.getters.currentUser.id;
      const trainingId = this.training.id;

      try {
        await axios.post('/api/trainings/mark-viewed', { userId, trainingId });

        Swal.fire({
          title: '¡Capacitación completada!',
          text: 'La capacitación se ha completado exitosamente.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });

        this.$router.push('/trainings');
      } catch (error) {
        console.error('Error al marcar la capacitación como vista', error);
      }
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style scoped>
.training-detail {
  background: #F2F2F2;
  text-align: center; 
}

.training-title {
  font-family: 'ColgateReady-BoldItalic';
  font-size: 3em; 
  color: #d2010d; 
  text-align: center; 
  margin-bottom: 16px; 
  padding: 0.9em 0 0;
}

@media (max-width: 1024px) {
  .training-title {
    font-size: 2.5em; 
  }
}

@media (max-width: 768px) {
  .training-title {
    font-size: 2em; 
  }
}

@media (max-width: 480px) {
  .training-title {
    font-size: 1.8em; 
  }
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px; 
  margin: 0 auto; 
  padding: 0 16px; 
  aspect-ratio: 16 / 9; 
  position: relative;
  background-color: #F2F2F2; 
  border-radius: 8px; 
  overflow: hidden; 
  box-sizing: border-box; 
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.training-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff; /* Fondo blanco */
  padding: 20px 0;
}

.description-title {
  font-family: 'ColgateReady-BoldItalic';
  font-size: 2.4em;
  color: #595655;
  text-align: center;
  margin-bottom: 1em;
}

.description-box {
  max-width: 60%;
  width: 100%;
  border-radius: 1em;
  background-color: #F2F2F2;
  text-align: left;
  padding: 2em;
  box-sizing: border-box;
}

.description-box p {
  font-family: 'ColgateReady-Light';
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  margin: 0;
}

@media (max-width: 1024px) {
  .description-box {
    max-width: 80%;
    padding: 1.5em;
  }

  .description-box p {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .description-box {
    max-width: 90%;
    padding: 1.2em;
  }

  .description-box p {
    font-size: 0.95em;
    line-height: 1.5;
  }

  .description-title {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .description-box {
    max-width: 95%;
    padding: 1em;
  }

  .description-box p {
    font-size: 0.9em;
    line-height: 1.4;
  }

  .description-title {
    font-size: 1.8em;
  }
}
</style>