<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from '@/components/auth/Login.vue';

export default {
  components: { Login }
};
</script>
