<template>
    <section class="section-container">
      <a href="#" @click.prevent="goToTrainings" class="link-button">
        <h1 style="font-size: 2em;">Ir a la Capacitación »</h1>
      </a>
    <h1 class="section-title">Aquí encontrarás</h1>

    <div class="card-grid">
      <!-- Tarjeta 1 -->
      <div class="card">
        <img src="../../assets/images/videos.png" alt="Icono Videos Explicativos">
        <h3>Videos Explicativos</h3>
        <p>Videos cortos referentes a los productos Colgate, donde se explican; formulación, beneficios y recomendaciones, de acuerdo a cada condición. <br><br>
        Los videos tienen una duración no mayor a 4 minutos.</p>
      </div>
      
      <!-- Tarjeta 2 -->
      <div class="card">
        <img src="../../assets/images/encuesta.png" alt="Icono Encuesta Evaluativa">
        <h3>Encuesta Evaluativa</h3>
        <p>Al final de cada video responde una breve encuesta, que te ayudará a fortalecer lo aprendido, con preguntas de selección múltiple.</p>
      </div>
      
      <!-- Tarjeta 3 -->
      <div class="card">
        <img src="../../assets/images/descarga.png" alt="Icono Materiales para Descargar">
        <h3>Materiales para Descargar</h3>
        <p>Este material es una guía práctica que podrá descargar una vez finalice la encuesta, que contiene la información resumida de cada producto Colgate. <br><br>
        Así podrá tener la información a la mano.</p>
      </div>
    </div>
  </section>
</template>
  
<script>
export default {
  methods: {
    goToTrainings() {
      // Redirigir manualmente a TrainingList
      this.$router.push('/trainings');
    },
  },
};
</script>
  
<style>
* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    /* Contenedor de la sección */
    .section-container {
      position: relative;
      width: 100%;
      height: 100vh;
      background-color: #ffffff; /* Fondo de la sección */
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    /* Botón tipo enlace */
    .link-button {
      width: 100%;
      height: 5em;
      background-color: #009ca6; /* Color de fondo inicial */
      color: #ffffff; /* Color del texto inicial */
      font-size: 2em;
      font-family: 'ColgateReady-BoldItalic';
      font-weight: bold;
      text-align: center;
      line-height: 5em;
      text-decoration: none;
      border-top: 0 solid #009ca6;
      border-bottom: 0 solid #009ca6;
      transition: background-color 0.3s, color 0.3s, border 0.3s;
    }

    /* Efecto de hover y foco (click) */
    .link-button:hover,
    .link-button:focus {
      background-color: #ffffff; /* Cambia a fondo blanco */
      color: #009ca6; /* Cambia el color del texto */
      border-top: 4px solid #009ca6; /* Borde superior en color #009ca6 */
      border-bottom: 4px solid #009ca6; /* Borde inferior en color #009ca6 */
    }

    /* Título debajo del botón */
    .section-title {
      margin-top: 1em;
      font-size: 3em;
      color: #d2010d;
      font-family: 'ColgateReady-BoldItalic';
      font-weight: bold;
      text-align: center;
    }

    /* Contenedor del grid */
    .card-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      width: 100%;
      max-width: 1200px;
      margin-top: 2em;
      padding: 0px 10px;
    }

    /* Estilos de cada tarjeta */
    .card {
      background-color: #f2f2f2;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    /* Imagen dentro de la tarjeta */
    .card img {
      width: 50%;
      border-radius: 5px;
      margin-bottom: 23px;
    }

    /* Título de la tarjeta */
    .card h3 {
      color: #d2010d;
      font-family: 'ColgateReady-Regular';
      font-size: 2em;
      margin-bottom: 23px;
    }

    /* Párrafo de la tarjeta */
    .card p {
      text-align: left;
      color: #595655;
      font-family: 'ColgateReady-Light';
      font-size: 1.2em;
      line-height: 1.5;
    }

    /* Adaptabilidad para pantallas más pequeñas */
    @media (max-width: 768px) {
      .card-grid {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 14px;
      }
    }

    @media (max-width: 480px) {
      .card-grid {
        grid-template-columns: 1fr;
        padding-bottom: 14px;
      }
    }

    /* Adaptabilidad para pantallas más pequeñas */
    @media (max-width: 768px) {
      .link-button {
        font-size: 1.2em;
      }
      .section-title {
        font-size: 1.5em;
      }
    }

    @media (max-width: 480px) {
      .link-button {
        font-size: 1em;
      }
      .section-title {
        font-size: 1.2em;
      }
    }  
</style>