<template>
  <section class="auth-section">
    <!-- Contenedor izquierdo -->
    <div class="form-container" style="background: #f2f2f2">

      <img src="../../assets/images/LogoVisita.png" alt="Imagen Superior" class="top-image" />

      <div class="form-content">
      <h1 class="title">Registrarse</h1>
      <form @submit.prevent="registerUser">
        <div class="form-group">
        <label for="distribuidor">Distribuidor que lo atiende <span>*</span></label> <br>
          <select v-model="distribuidor" id="distribuidor" required>
            <option value="" disabled selected>Elige una opción</option>
            <option v-for="dist in distribuidores" :key="dist.id" :value="dist.id">
              {{ dist.nombre }}
            </option>
          </select>
        </div><br>

        <div class="form-group checkbox-group">
          <input type="checkbox" v-model="occ" />  
          <label for="occ"> OCC</label>
        </div><br>

        <div class="form-group select-row">
        <div class="select-item">
        <label for="region">Región <!-- <span>*</span> --></label> <br>
        <select v-model="region" @change="fetchZonas" id="region" required>
          <option value="" disabled selected>Elige una opción</option>
          <option v-for="reg in regiones" :key="reg.id" :value="reg.id">
            {{ reg.nombre }}
          </option>
        </select>
        </div>

        <div class="select-item">
        <label for="zona">Zonas Equipo ACF <!-- <span>*</span> --></label> <br>
        <select v-model="zona" @change="fetchPuntosDeVenta" id="zona" :disabled="!region">
          <option value="" disabled selected>Elige una opción</option>
          <option v-for="zon in zonas" :key="zon.id" :value="zon.id">
            {{ zon.nombre }}
          </option>
        </select>
        </div>

        <div class="select-item">
        <label for="puntoVenta">Punto de Venta <!-- <span>*</span> --></label> <br>
        <select v-model="puntoVenta" id="puntoVenta" required :disabled="!zona">
          <option value="" disabled selected>Elige una opción</option>
          <option v-for="punto in puntosDeVenta" :key="punto.id" :value="punto.id">
            {{ punto.nombre }}
          </option>
        </select>
        </div>
      </div><br>

        <div class="form-group">
        <label for="codigo_pdv">Código IPV ACF <span>(opcional)</span></label> <br>
        <input v-model="codigo_pdv" type="text" id="codigo_pdv" placeholder="Código IPV ACF" /> 
        </div><br>  

        <div class="form-group">
        <label for="email">Correo Electronico <span>*</span></label> <br>
        <input v-model="email" type="email" id="email" placeholder="Correo Electrónico" required />
        </div><br>  

        <div class="form-group">
        <label for="celular">Celular <span>*</span></label> <br>
        <input v-model="celular" type="text" id="celular" placeholder="Celular" required/>
        </div><br>

        <div class="button-container">
          <button type="submit" class="register-button">Registrarse</button>
        </div>  
        </form>

        <div class="login-prompt">
          <p>Ya estoy registrado</p>
          <router-link to="/login" class="login-link">Iniciar Sesión</router-link>
        </div>
    </div>
    </div>

    <!-- Contenedor derecho: Imagen como elemento <img> -->
    <div class="image-container">
      <img src="../../assets/images/imgportada.png" alt="Imagen de Registro" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      distribuidores: [],
      regiones: [],
      zonas: [],
      puntosDeVenta: [],
      distribuidor: "",
      occ: false,
      region: "",
      zona: "",
      puntoVenta: "",
      codigo_pdv: "",
      email: "",
      celular: "",
    };
  },
  methods: {
    async fetchDistribuidores() {
      const response = await axios.get("/api/data/distribuidores");
      this.distribuidores = response.data;
    },

    async fetchRegiones() {
      const response = await axios.get("/api/data/regiones");
      this.regiones = response.data;
    },

    async fetchZonas() {
      if (this.region) {
        const response = await axios.get(`/api/data/zonas/${this.region}`);
        // this.zonas = response.data;
        this.zonas = response.data.filter(
          (zona) => zona.nombre !== "CUNDINAMARCA CHAPINERO" && zona.nombre !== "PACIFICO SUR"
        );
        this.zona = "";
        this.puntosDeVenta = [];
        this.puntoVenta = "";
      }
    },

    async fetchPuntosDeVenta() {
      if (this.zona) {
        const response = await axios.get(`/api/data/puntos_de_venta/${this.zona}`);
        this.puntosDeVenta = response.data;
        this.puntoVenta = "";
      }
    },

    async registerUser() {
      const user = {
        distribuidor: this.distribuidor,
        occ: this.occ,
        region: this.region,
        zona: this.zona,
        puntoVenta: this.puntoVenta,
        codigo_pdv: this.codigo_pdv,
        correo: this.email,
        celular: this.celular,
      };
      try {
        await axios.post("/api/auth/register", user);

        // success alert
        Swal.fire({
          title: 'Registro exitoso',
          text: 'El usuario se ha registrado correctamente.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });

        console.log("Registro exitoso: El usuario se ha registrado correctamente.");

        this.$router.push("/login");
      } catch (error) {
        console.error("Error al registrar usuario", error);
      }
    },
  },

  async created() {
    await this.fetchDistribuidores();
    await this.fetchRegiones();
  },
};
</script>

<style scoped>
.auth-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.form-container {
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-image {
  position: absolute;
  top: 0;
  width: 24%;
}

.form-content {
  width: 80%;
}

.title {
  text-align: center;
  font-family: 'ColgateReady-BoldItalic';
  font-size: 2.5em;
  color: #d2010d;
}

label {
  font-size: 1.2em;
  font-family: 'ColgateReady-Regular';
  color: #595655;
}

select,
input:not([type="checkbox"]) {
  width: 100%;
  color: #595655;
  font-size: 1em;
  border-radius: 2em;
  border: 2px solid transparent;
  padding: 0.3em 1em;
  height: 37px;
  outline: none;
}

select:focus,
input:not([type="checkbox"]):focus {
  border-color: #F1B2B6;
}

option {
  font-size: 1.2em;
}

span {
  font-family: 'ColgateReady-Regular';
  color: #00A6C0;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/* Nuevo estilo para los select en fila */
.select-row {
  display: flex;
  gap: 1em;
}

.select-item {
  flex: 1;
}

.button-container {
  display: flex;
  justify-content: center;
}

.register-button {
  background-color: #d2000b;
  width: 470px;
  height: 47px;
  color: #fff;
  font-size: 1.2em;
  font-family: 'ColgateReady-Bold';
  border: none;
  border-radius: 2em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #f0000c;
}

/* Estilo para centrar el texto y el enlace de inicio de sesión */
.login-prompt {
  text-align: center;
  margin-top: 1em;
  font-family: 'ColgateReady-Regular';
  color: #595655;
}

.login-prompt p {
  margin: 0;
}

.login-link {
  display: inline-block;
  margin-top: 0.5em;
  padding: 0.3em 1em;
  border: 1px solid #595655;
  border-radius: 2em;
  color: #595655;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, border-color 0.3s;
}

.login-link:hover {
  background: #00A6C0;
  color: #fff;
  border-color: #009ca6;
}

/* Media Queries para dispositivos medianos y pequeños */
@media (max-width: 1024px) {
  .auth-section {
    grid-template-columns: 1fr;
  }

  .image-container {
    display: none;
  }

  .form-container {
    width: 100%;
  }

  .register-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2em;
  }

  input,
  select {
    font-size: 0.9em;
    padding: 0.3em;
  }

  .register-button {
    font-size: 1em;
  }
}

.top-image {
  position: absolute;
  top: 0;
  width: 24%;
  max-width: 200px; 
}

@media (max-width: 768px) {
  .top-image {
    width: 40%;
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .auth-section {
    padding-bottom: 1em;
  }
  
  .select-row {
    flex-direction: column; 
    gap: 0.5em; 
  }

  .select-item {
    flex: none; 
  }
}

</style>
